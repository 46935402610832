.slide {
    // width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $base*4 0;
    background-image: linear-gradient($dark, black);
    overflow: hidden;
    position: relative;

    @include for-tablet-landscape-up {
        padding: 0;     
        min-height: 100vh;
        flex-direction: row;
        justify-content: flex-start;
    }

    &:nth-of-type(even) {
        background-image: linear-gradient(black, $dark);
    }

    .coverbg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            object-fit: cover;
            object-position:50% 50%;
            height: 100%;
            opacity: 0.5;
        }

        video {
            object-fit: cover;
            object-position:50% 50%;
            height: 100%;
            opacity: 0.5;
        }
    }

    &.first {
        min-height: 100vh;
        padding: 0;
        justify-content: center;
        // background: black;
        // background-image: linear-gradient(black, $dark);
        // background: url('../images/MadMash3.PNG') no-repeat center center;
        // background-size: cover; 

        @include for-tablet-landscape-up {
            .slide-inner {
                text-align: center;

                h1{
                    text-shadow: 1px 1px 2px silver, 0 0 1em skyblue, 0 0 0.2em teal;
                }

                h2 {
                    color: white;
                }
            }
        }
    }

    .slide-inner {
        text-align: center;
        max-width: 84vw;
        margin: 0 auto;

        @include for-tablet-landscape-up {
            max-width: 84vw;
            text-align: left;
        }

        h1 {
            font-size: 300%;
            margin-bottom: 0;
            line-height: 100%;
            text-transform: uppercase;
            position: relative;

            @include for-tablet-landscape-up {
                font-size: 400%;
            }

            @include for-desktop-up {
                font-size: 520%;
            }

            &.hero {
                font-size: 420%;

                sup {
                    font-weight: normal;
                    font-size: 20%;
                    position: absolute;
                    top: -50%;
                }

                @include for-tablet-landscape-up {
                    font-size: 1000%;
                }
            }
        }

        h2 {
            font-size: 160%;
            line-height: 1.2;
            font-weight: normal;
            text-transform: uppercase;
            color: $orange;
            margin-bottom: $base/2;

            @include for-tablet-landscape-up {
                font-size: 180%;
                margin-bottom: 0;
            }

            @include for-desktop-up {
                font-size: 220%;
                margin-bottom: 0;
            }
        }

        p {
            font-size: 120%;
            line-height: 1.416;

            @include for-tablet-landscape-up {
                font-size: 140%;
            }

            @include for-desktop-up {
                font-size: 160%;
            }
        }
    }
}

.cols {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include for-tablet-landscape-up {
        flex-direction: row;
    }

    .slide-col {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: top;

        @include for-tablet-landscape-up {
            width: 55%;
            &:first-child {
                width: 45%;
            }
        }

        .slide-img {
            width: 100%;
            text-align: center;
            max-height: 64vh;
            overflow: hidden;

            @include for-tablet-landscape-up {
                // height: auto;
                // &:first-child {
                //     width: 80%;
                // }
            }

            img{
                width: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                outline: 1px solid #333;
                outline-offset: 4px;
                border-radius: 2px;

                @include for-tablet-landscape-up {
                    width: 80%;
                    margin-top: $base*3.5;
                }
            }

            video{
                height: auto;
                width: 100%;
                background-size: cover;
                outline: 1px solid #333;
                outline-offset: 4px;
                border-radius: 2px;

                @include for-tablet-landscape-up {
                    margin-top: $base*3.5;
                    width: 80%;
                    height: 90%;
                    object-fit: cover;
                }
            }
        }

        .caption {
            text-align: center;
            padding: $base $base 0 $base;
            color: $mid;

            @include for-tablet-landscape-up {        
                padding: $base*2 $base*5 0 $base*5;
            }
        }
    }
}