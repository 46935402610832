nav {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 10000;
    background: linear-gradient($dark, rgba(0,0,0,1));
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .logo {
        margin: $base*1.25 $base*1.75;
    }

    .menu_toggle {
        margin: $base*1.25;

        @include for-tablet-landscape-up {
            display: none;
        }
    }

    ul {
        padding: $base 0;
        top: $base*3;
        left: 0;
        right: 0;
        bottom: -1em;
        position: fixed;
        background: linear-gradient($dark, rgba(0,0,0,0.8));
        transform: translateY(-32px);
        opacity: 0;
        transition: $transition;
        pointer-events: none;

        &.showMenu {
            transform: translateY(0);
            opacity: 1;
            pointer-events: all;
        }

        li {
            padding: $base*1.5 0 $base 0;
            margin: 0 $base*2;
            list-style: none;
            
            a {
                color: white;
                text-decoration: none;
                text-transform: uppercase;
                transition: $transition;

                &:hover {
                    color: $orange;
                }
            }

            @include for-tablet-landscape-up {
                padding: $base*1.5 0;
            }
        }

        @include for-tablet-landscape-up {
            position: relative;
            display: flex;
            opacity: 1;
            padding: 0;
            margin: 0;
            top: 0;
            bottom: 0;
            transform: translateY(0);
            pointer-events: all;
        }
    }
}