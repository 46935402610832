html, body {
    font-family: 'Abel', sans-serif;
    padding: 0;
    margin: 0;
    background: $dark;
    color: white;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

h1, h3 {
    font-family: 'Teko', sans-serif;
    
    @include for-tablet-landscape-up {
        letter-spacing: 4px;
    }
}

button {
    background: none;
    border: none;

    svg {
        stroke: white;
        transform: scale(1.8);
    }
}

a {
    color: orange;

    &:hover {
        color: $orange;
    }

    &.anchor {
        visibility: hidden;
        height: 1px;
        display: block;
        overflow: hidden;
    }
}